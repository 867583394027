import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "eingelegter Tofu",
  "preis": "3,00-5,00 € pro 100g"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Tofu kann viel. Vor Allem viel Geschmack aufnehmen. Deshalb legen wir unseren Naturtofu in Salzlake und Gewürze ein, lassen ihn ein paar Tage ziehen und dann ist er ready für den Verzehr.
Cremig, würzig, gut. Und Vegan.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      